<template>
  <PageWithLayout>
    <p class="desc_info">💡 지정 후 저장버튼을 눌러야 반영됩니다.</p>
    <Board
      :class="`board_${viewModel.tabData.value.toLowerCase()}`"
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList[viewModel.tabData.value]"
      :paginationData="viewModel.paginationData">
      <template v-slot:tbl_tab>
        <Tab
          :tabData="viewModel.tabData"
          :value.sync="viewModel.tabData.value"
          @update:value="value => viewModel.onUpdateTab(value)"/>
      </template>
    </Board>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="되돌리기"
          :disabled="viewModel.isDisabledResetBtn"
          @onClickBtn="viewModel.onClickReset()">
          <IconSvg
            iconName="reset"
            :size="12"
            :iconColor="viewModel.isDisabledResetBtn ? 'DISABLED_ICON' : '#222'"
            :iconDarkColor="viewModel.isDisabledResetBtn ? 'DISABLED_ICON_DARK' : '#fff'"/>
      </Button>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          @onClickBtn="viewModel.onClickComplete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Tab from '@lemontree-ai/lemontree-admin-common-front/components/common/tab/Tab';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// viewModel
import MapOrderViewModel from '@/views/worldMap/mapOrder/viewModel/MapOrderViewModel'

export default {
  name:'MapOrder',
  components:{
    PageWithLayout,
    Tab,
    Board,
    Sticky,
    Button,
    IconSvg,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new MapOrderViewModel(),
      tabData:{
        dataList: [
          { id: 'tab1', text:'tab1' },
          { id: 'tab2', text:'tab2' },
        ],
        option:{
          isCount: false,
          queryKeyName: 'tabValue'
        },
        value: 'tab1'
      },
    }
  },
}
</script>

<style scoped>
.desc_info + .board_comm{margin-top:20px}

.board_comm::v-deep .fold .tit_question{display:block;padding-bottom:9px;line-height:20px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.board_comm::v-deep .fold .txt_tbl{display:block;overflow:hidden;height:29px;margin:9px 0 0;line-height:22px;}
.board_comm::v-deep .tr_fold .txt_tbl{height:auto;margin-bottom:9px;overflow:inherit;white-space:inherit;text-overflow:inherit}
.board_comm::v-deep .tr_fold .tit_question{overflow:visible;white-space:inherit;text-overflow:inherit;color:#222}
.board_comm::v-deep .tr_fold .editor_view p{min-height:22px;font-size:14px;line-height:22px;color:#666}
/* .board_comm::v-deep .icon_firfin{margin-top:10px}
.board_comm::v-deep .tr_fold .icon_firfin{transform:scaleY(-1)} */

.board_plus >>> tbody tr:nth-child(1) .td_title .txt_tbl,
.board_plus >>> tbody tr:nth-child(2) .td_title .txt_tbl{position:relative}
.board_plus >>> tbody tr:nth-child(1) .td_title .txt_tbl:after,
.board_plus >>> tbody tr:nth-child(2) .td_title .txt_tbl:after{position:absolute;top:0;left:100%;margin-left:8px;content:'new';
padding: 2px 4px;background-color: rgb(226, 60, 60);border-radius: 100px;text-align: center;color: rgb(255, 255, 255);font-size: 12px;font-weight: 600;line-height: 16px}


@media (prefers-color-scheme: dark) {
  .board_comm::v-deep .tr_fold .tit_question{color:#fff}
}
/* 모바일 */
@media all and (max-width:1240px){
  .board_comm::v-deep .fold .txt_tbl{margin:0;padding:0}
}
</style>